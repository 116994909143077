import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './Css/Buyurtmalar.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next' // Import useTranslation hook

const Buyurtmalar = () => {
  const { t } = useTranslation() // Get the translation function
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  // Fetching orders with authorization token
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true)
      setError(null)
      try {
        const token = localStorage.getItem('userToken')

        // If token is not found, redirect to '/hisobim'
        if (!token) {
          setError('Authorization token not found.')
          navigate('/hisobim')
          setLoading(false)
          return
        }

        // Set up headers with authorization token
        const headers = {
          Authorization: `Bearer ${token}`,
        }

        // Make the GET request with axios
        const response = await axios.get(
          'https://qizildasturchi.uz/api/orders',
          { headers }
        )

        if (response.status === 401) {
          // JWT expired or invalid token
          alert('Your session has expired. Please log in again.')
          navigate('/hisobim')
          return
        }

        // Set orders data
        setOrders(response.data)
        setLoading(false)
      } catch (error) {
        navigate('/hisobim')
        console.error('Error fetching orders:', error)
        setError(t('errorLoading')) // Use translation for error message
        setLoading(false)
      }
    }

    fetchOrders()
  }, [navigate, t])

  if (loading) {
    return <div>{t('loading')}</div> // Use translation for loading message
  }

  if (error) {
    return <div>{error}</div> // Display the error message
  }

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return t('orderStatus.new')
      case 2:
        return t('orderStatus.accepted')
      case 3:
        return t('orderStatus.delivered')
      case 4:
        return t('orderStatus.paid')
      case 0:
        return t('orderStatus.canceled')
      default:
        return t('orderStatus.unknown')
    }
  }

  const truncateAddress = (address) => {
    if (address.length > 150) {
      return address.substring(0, 150) + '...'
    }
    return address
  }

  return (
    <div className="buyurtmalar-page p-4">
      <div className="order-list grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {orders.data.length === 0 ? (
          <h1 className="text-center text-lg text-gray-700">
            {t('noOrdersMessage')}
          </h1>
        ) : (
          orders.data.map((order) => (
            <div
              key={order.id}
              className="order-card bg-white shadow-lg rounded-lg p-6 border border-gray-200 transition-shadow duration-300 hover:shadow-xl flex flex-col"
            >
              <div className="order-details flex-grow">
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-700">
                    {t('orderStatusText')}: {getStatusText(order.status)}
                  </span>
                  <span
                    className={`px-3 py-1 rounded-full text-xs font-bold ${
                      order.payment_status === 1
                        ? 'bg-red-100 text-red-700'
                        : order.payment_status === 2
                        ? 'bg-green-100 text-green-700'
                        : order.payment_status === -1
                        ? 'bg-yellow-100 text-yellow-700'
                        : 'bg-gray-100 text-gray-700'
                    }`}
                  >
                    {order.payment_status === 1
                      ? t('paymentStatus.unpaid')
                      : order.payment_status === 2
                      ? t('paymentStatus.paid')
                      : order.payment_status === -1
                      ? t('paymentStatus.refunded')
                      : t('paymentStatus.unknown')}
                  </span>
                </div>
                <div className="text-gray-500 text-sm">
                  {t('orderDate')}:{' '}
                  {new Date(order.created_at).toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                  })}
                </div>
                <p className="card-subtitle mb-2">
                  Manzil:{' '}
                  {truncateAddress(
                    [order.country, order.region, order.city, order.address]
                      .filter(Boolean)
                      .join(', ')
                  )}
                </p>
                <div className="font-bold text-lg text-green-600">
                  {t('totalSum')}: {order.total_sum.toLocaleString()} so'm
                </div>

                <div className="order-products mt-4">
                  <h3 className="text-md font-semibold text-gray-600 mb-2">
                    {t('products')}:
                  </h3>
                  {order.products.map((item) => (
                    <div
                      key={item.id}
                      className="product-item bg-gray-50 p-4 rounded-lg border flex items-center mb-4"
                    >
                      <img
                        src={
                          `https://qizildasturchi.uz/image${item.product.image}` ||
                          'placeholder.jpg'
                        }
                        alt={item.product.name}
                        className="w-32 h-32 object-cover rounded-md mr-4"
                      />
                      <div className="flex-1">
                        <div className="font-semibold text-gray-700 text-lg">
                          {item.product.name}
                        </div>
                        <div className="text-sm text-gray-500">
                          {t('quantity')}: {item.count} ta
                        </div>
                        <div className="text-sm text-gray-600">
                          {t('totalPrice')}:{' '}
                          {(item.product.price * item.count).toLocaleString()}{' '}
                          so'm
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default Buyurtmalar
