import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './Css/Savatcha.css'
import { Buffer } from 'buffer'

function Savatcha() {
  const { t } = useTranslation()
  const [cartItems, setCartItems] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [address, setAddress] = useState('')
  const [country, setCountry] = useState('Uzbekiston')
  const [region, setRegion] = useState('')
  const [city, setCity] = useState('')

  useEffect(() => {
    const userToken = localStorage.getItem('userToken')
    const userId = localStorage.getItem('userId')

    if (!userToken || !userId) {
      navigate('/hisobim')
    }

    const savedCart = localStorage.getItem('cart')
    if (savedCart) {
      setCartItems(JSON.parse(savedCart))
    }
  }, [navigate])

  const handlePurchase = async () => {
    // Check if all required fields are filled
    if (!region || !city || !address) {
      alert("Iltimos barcha maydonlarni to'ldiring!")
      return
    }

    setLoading(true)
    try {
      const orderData = cartItems.map((item) => ({
        product_id: item.id,
        count: item.count,
      }))
      const userToken = localStorage.getItem('userToken')
      const userId = localStorage.getItem('userId')

      const response = await fetch('https://qizildasturchi.uz/api/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          user_id: userId,
          products: orderData,
          country,
          region, // Send region name
          city, // Send city name
          address,
        }),
      })

      const result = await response.json()

      console.log(result)
      if (response.status === 401) {
        alert(t('carts.tokenExpired'))
        navigate('/hisobim')
      } else if (result.success) {
        localStorage.removeItem('cart')
        setCartItems([])

        const base = Buffer.from(
          `m=67189981e51de1c6a3a67da2;ac.order_id=${result.data.id};a=${
            result.data.total_sum * 100
          }`
        ).toString('base64')
        window.location.href = `https://checkout.paycom.uz/${base})`
      } else {
        alert(result.message || t('carts.purchaseError'))
      }
    } catch (error) {
      console.error('Error placing order:', error)
      alert(t('alerts.networkError'))
    } finally {
      setLoading(false)
    }
  }

  const updateCart = (id, newCount) => {
    const updatedCart = cartItems
      .map((item) => (item.id === id ? { ...item, count: newCount } : item))
      .filter((item) => item.count > 0)
    setCartItems(updatedCart)
    localStorage.setItem('cart', JSON.stringify(updatedCart))
  }

  const increaseCount = (id) => {
    const item = cartItems.find((item) => item.id === id)
    if (item) {
      updateCart(id, item.count + 1)
    }
  }

  const decreaseCount = (id) => {
    const item = cartItems.find((item) => item.id === id)
    if (item && item.count > 1) {
      updateCart(id, item.count - 1)
    } else if (item) {
      updateCart(id, 0)
    }
  }

  const calculateTotalSum = (cart) => {
    return cart.reduce((total, item) => total + item.price * item.count, 0)
  }

  const handleAddressChange = (e) => {
    setAddress(e.target.value)
  }

  const handleCountryChange = (e) => {
    setCountry(e.target.value)
  }

  const handleRegionChange = (e) => {
    setRegion(e.target.value)
  }

  const handleCityChange = (e) => {
    setCity(e.target.value)
  }

  return (
    <div className="savatcha-container p-4">
      {cartItems.length === 0 ? (
        <h1 className="text-center text-lg text-gray-700">
          {t('carts.empty')}
        </h1>
      ) : (
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {cartItems.map((item) => (
            <li
              key={item.id}
              className="cart-item bg-white shadow-lg rounded-lg p-4 flex flex-col transition-shadow duration-300 hover:shadow-xl"
            >
              <img
                src={
                  item.image?.startsWith('https://qizildasturchi.uz/image')
                    ? `${item.image}`
                    : `https://qizildasturchi.uz/image${item.image}`
                }
                alt={item.name}
                className="cart-item-image w-full h-32 object-cover rounded-md mb-4"
              />

              <div className="cart-item-details flex-grow">
                <span className="cart-item-name font-semibold text-lg text-gray-800">
                  {item.name}
                </span>
                <p className="text-gray-600">
                  {item.count} {t('pieces')} - {item.price * item.count}{' '}
                  {t('cart.currency')}{' '}
                </p>
              </div>

              <div className="flex justify-between mt-4">
                <button
                  onClick={() => decreaseCount(item.id)}
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 text-lg"
                >
                  -
                </button>
                <button
                  onClick={() => increaseCount(item.id)}
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 text-lg"
                >
                  +
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {cartItems.length === 0 ? (
        <div className="savat-buttonlar mt-4 flex justify-center space-x-4">
          <button
            onClick={() => navigate('/')}
            className="bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600 text-lg"
          >
            {t('buttons.home')}
          </button>
          <button
            onClick={() => navigate('/buyurtmalar')}
            className="bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600 text-lg"
          >
            {t('buttons.orders')}
          </button>
        </div>
      ) : (
        <div className="flex flex-col items-center mt-4">
          <h4 className="font-semibold text-lg">
            {t('carts.total')}: {calculateTotalSum(cartItems)}
            {t('cart.currency')}
          </h4>

          <div className="address mt-6 w-full max-w-md space-y-4">
            <div className="relative">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                {t('country')}
              </label>
              <input
                type="text"
                name="country"
                id="country"
                onChange={handleCountryChange}
                className="block w-full mt-2 px-4 py-2 text-lg border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
                placeholder={t('country')}
              />
            </div>

            <div className="relative">
              <label
                htmlFor="region"
                className="block text-sm font-medium text-gray-700"
              >
                {t('region')}
              </label>
              <input
                id="region"
                onChange={handleRegionChange}
                value={region}
                className="block w-full mt-2 px-4 py-2 text-lg border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
                placeholder={t('region')}
              />
            </div>

            <div className="relative">
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                {t('city')}
              </label>
              <input
                id="city"
                onChange={handleCityChange}
                value={city}
                className="block w-full mt-2 px-4 py-2 text-lg border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
                placeholder={t('city')}
              />
            </div>

            <div className="relative">
              <label
                htmlFor="address"
                className="block text-sm font-medium text-gray-700"
              >
                {t('street')}
              </label>
              <input
                id="address"
                onChange={handleAddressChange}
                value={address}
                rows={3}
                placeholder={t('street')}
                className="block w-full mt-2 px-4 py-2 text-lg border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <button
              onClick={handlePurchase}
              disabled={loading}
              className="w-full mt-4 bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed text-lg"
            >
              {loading ? t('buttons.home') : t('buttons.buy')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Savatcha
